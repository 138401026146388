import React, { useRef } from "react";
import "../../styles/components/footer.css";

import tcLogo from "../../images/logos/logo-tc.svg";

import sendIcon from "../../images/icons/send-01.svg";

import fbIcon from "../../images/icons/social/facebook.svg";
import igIcon from "../../images/icons/social/instagram.svg";
import lkIcon from "../../images/icons/social/linkedin.svg";
import twIcon from "../../images/icons/social/twitter.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  const refInputMail = useRef(null);
  const onSubmitNewsLetter = (e) => {
    e.preventDefault();
  };
  return (
    <footer>
      <div className="content | flcolm max-container">
        <div className="tc-container | flrow liner btw">
          <div className="tc-side | flcolm">
            <button>
              <img src={tcLogo} alt="trouvechap-icon" />
            </button>
            <ul className="page-link | flrow addgap24">
              <li>
                <Link to={""}>A propos</Link>
              </li>
              <li>
                <Link to={""}>Termes et conditions</Link>
              </li>
              <li>
                <Link to={""}>Partenaires</Link>
              </li>
              <li>
                <Link to={""}>Lister ma résidence <span> sur Trouvechap</span></Link>
              </li>
            </ul>
          </div>
          <div className="news-side | flcolm addgap24">
            <p>
              Recevez les dernières informations sur les nouveaux services et
              nouveaux produits de Trouvechap.
            </p>
            <form onSubmit={onSubmitNewsLetter} className="flrow liner addgap8">
              <input
                ref={refInputMail}
                type="email"
                placeholder="Entrez votre adresse email"
              />
              <button type="submit" className="flrow liner addgap8">
                <span>Envoyer</span>
                <img src={sendIcon} alt="send-icon" />
              </button>
            </form>
          </div>
        </div>
        <div className="info-tc | flrow liner btw">
          <button>
            <img src={tcLogo} alt="trouvechap-icon" />
          </button>
          <span>© 2023 Trouvechap. Tous droits réservés.</span>
          <ul className="social | flrow addgap16">
            <li>
              <a
                href="http://localhost:3000"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fbIcon} alt="fbIcon" />
              </a>
            </li>
            <li>
              <a
                href="http://localhost:3000"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={igIcon} alt="igIcon" />
              </a>
            </li>
            <li>
              <a
                href="http://localhost:3000"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={lkIcon} alt="lkIcon" />
              </a>
            </li>
            <li>
              <a
                href="http://localhost"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twIcon} alt="twIcon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
