import "../styles/pages_styles/homePage.css";
import Footer from "../components/biggests/Footer";
import VideoFooter from "../components/biggests/VideoFooter";

export default function HomePage() {
  return (
    <main>
      <p>Header</p>
      <p>HeroSection</p>
      <p>PopuResidence</p>
      <p>MoyenPaiement</p>
      <p>Lister ma PopuResidence</p>
      <p>How work trouvechap</p>
      <p>Mobile app</p>
      <p>Testimonial</p>

      <VideoFooter />

      <Footer />
    </main>
  );
}
