import HomePage from "./pages/HomePage";
import "./styles/initialize/App.css";
// import VideoLoop from "./VideoLoop";
function App() {
  return (
    <>
      {/* <VideoLoop /> */}
      <HomePage />
    </>
  );
}

export default App;
