import React, { useState, useRef } from "react";
import coverLoaderImg from "../../images/temps/overlay.jpg";
import pauseIcon from "../../images/icons/pause-circle.svg";
import playIcon from "../../images/icons/playVideo.svg";
import "../../styles/components/videoLoop.css";
export const VideoLoop = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <>
      <video
        className="home-video"
        ref={videoRef}
        autoPlay
        loop
        muted
        onLoadedData={handleVideoLoad}
        style={{ display: isVideoLoaded ? "block" : "none" }}
      >
        <source
          src="https://res.cloudinary.com/dmtgryxvl/video/upload/v1695591188/trouvechap-testvideo_aca2fc.mp4"
          type="video/mp4"
        />
        Votre navigateur ne prend pas en charge les videos.
      </video>
      {!isVideoLoaded ? (
        <img className="coverLoader" src={coverLoaderImg} alt="Loading..." />
      ) : (
        <button
          className="btn-action-video | center-absolute"
          onClick={togglePlay}
          style={{ opacity: isPlaying ? 0 : 0.5 }}
        >
          {isPlaying ? (
            <img src={pauseIcon} alt="pause-icon" />
          ) : (
            <img src={playIcon} alt="play-icon" />
          )}
        </button>
      )}
    </>
  );
};
